import React from "react"
import Img from "gatsby-image"
const BlogPostHeader = ({ image }) => {
  return (
    <section>
      <div className="blog-post-header">
      <img className="blog-post-header-image" src={image} alt="blog image" />
      </div>

    </section>
  )
}
export default BlogPostHeader
